// Removed unused 'lazy' and 'Navigate' imports
import { Navigate, useRoutes } from 'react-router-dom';
import AdminLogin from './pages/admin/Login'
import RegisterPage from './pages/admin/Register'
import Dashboard from './pages/Dashboard';
import Records from './pages/Records';
import Inventory from './pages/Inventory';
import Settings from './pages/Settings';
// import CalendarPage from './components/calendar/calendar';
import CalendarPage from './components/Calendar/Calendar';
import CommonLoginPage from './pages/admin/commonLogin';
import AdminLoginPage from './pages/admin/adminLogin';
import StudentLoginPage from './pages/admin/studentLogin';
import Training from './pages/Training';
import { useState } from 'react';
import ProtectedRoute from './utils/ProtectedRoute';
import { ToastContainer } from 'react-toastify';
import Messages from './pages/Messages';
import OrganizationAdmin from './pages/admin/organizationalAdmin';
import ForgotPasswordPage from './pages/admin/ForgotPassword' 
import ResetPasswordPage from './pages/admin/ResetPassword';
import ProfilePage from './pages/Profile';
import Project from './pages/admin/Projects';
import PublicRoute from './utils/PublicRoute';

export const AppRoutes = () => {
    const [collapsed, setCollapsed] = useState(false);
    const routes = [
        { path: "*", element: <Navigate to="/dashboard" replace /> },
        // { path: "/messages", element: <ProtectedRoute><Messages collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/dashboard", element: <ProtectedRoute><Dashboard collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/inventory", element: <ProtectedRoute><Inventory collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/records", element: <ProtectedRoute><Records collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/settings", element: <ProtectedRoute><Settings collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/calendar", element: <ProtectedRoute><CalendarPage collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        // { path: "/training", element: <ProtectedRoute><Training collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/profile", element: <ProtectedRoute><ProfilePage collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/project", element: <ProtectedRoute><Project collapsed={collapsed} setCollapsed={setCollapsed} /></ProtectedRoute> },
        { path: "/register", element: <PublicRoute><RegisterPage /></PublicRoute>},
        { path: "/login", element: <PublicRoute><CommonLoginPage /></PublicRoute> },
        { path: "/adminlogin", element: <PublicRoute><AdminLoginPage /></PublicRoute> },
        { path: "/organizationaladminlogin", element: <PublicRoute><OrganizationAdmin /></PublicRoute> },
        { path: "/forgot_password", element: <PublicRoute><ForgotPasswordPage /></PublicRoute> },
        { path: "/reset_password", element: <PublicRoute><ResetPasswordPage /></PublicRoute> },
        // More routes can be added here
    ];
    
    return useRoutes(routes);
};

import React, { useState } from 'react';
import { Box, Button, IconButton, Tabs, Tab, Typography, useMediaQuery, useTheme, Tooltip } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DownloadIcon from '@mui/icons-material/Download';
import AddInventoryDialog from './Popups/AddInventory';
import AddEquipmentDialog from './Popups/AddEquipment';
import AddMachineDialog from './Popups/AddMachine';
import ImportMachine from './Popups/ImportMachine';
import ImportEquipment from './Popups/ImportEquipment';
import ImportInventory from './Popups/ImportInventory';

const TopBarComponent = ({ activeTab, setActiveTab, update, setUpdate, flag, setFlag, token }) => {
  const [isAddInventoryOpen, setAddInventoryOpen] = useState(false);
  const [isAddEquipmentOpen, setAddEquipmentOpen] = useState(false);
  const [isAddMachineOpen, setAddMachineOpen] = useState(false);
  const [isImportMachineOpen, setImportMachineOpen] = useState(false);
  const [isImportEquipmentOpen, setImportEquipmentOpen] = useState(false);
  const [isImportInventoryOpen, setImportInventoryOpen] = useState(false);

  const handleAddButtonClick = () => {
    switch (activeTab) {
      case 0:
        setAddMachineOpen(true);
        break;
      case 1:
        setAddEquipmentOpen(true);
        break;
      case 2:
        setAddInventoryOpen(true);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    switch (activeTab) {
      case 0:
        setAddMachineOpen(false);
        break;
      case 1:
        setAddEquipmentOpen(false);
        break;
      case 2:
        setAddInventoryOpen(false);
        break;
      default:
        break;
    }
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const handleImportClick = () => {
    if (activeTab === 0) {
      setImportMachineOpen(true);
    } else if (activeTab === 1) {
      setImportEquipmentOpen(true);
    } else if (activeTab === 2) {
      setImportInventoryOpen(true);
    } else {
      alert('This feature is not available at the moment');
    }
  };

  return (
    <Box sx={{
      display: 'flex',
      flexDirection: isMobile ? 'column' : 'row',
      justifyContent: 'space-between',
      alignItems: 'center',
      gap: isMobile ? 1 : 0,
      width: '100%',
      padding: theme.spacing(1),
      margin: 'none',
      marginBlock: '1rem',
      boxSizing: 'border-box',
    }}>
      <Tabs
        value={activeTab}
        onChange={handleChange}
        indicatorColor="none"
        sx={{
          padding: '5px',
          width: 'auto',
          marginBottom: isMobile ? 1 : 0,
          marginRight: '17.5%',
          height: 'auto',
          background: '#F4F4F4',
          borderRadius: '12px',
          '& .Mui-selected': { background: '#E9E9E9', border: '1px solid #B5B5B5', borderRadius: '8px' }
        }}
      >
        <Tooltip title="Machine contains items which are fixed in TL and can be reserved by booking a free slot" arrow>
          <Tab label="Machines" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} />
        </Tooltip>
        <Tooltip title="Equipments contain items which are fixed in TL and do not require approval or reservations" arrow>
          <Tab label="Equipment" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} />
        </Tooltip>
        <Tooltip title="Inventory contains all items in the TL that can be shelved (inventory that is not fixed like machines or equipment)" arrow>
          <Tab label="Inventory" sx={{ marginInline: 0.5, padding: '8px 32px', width: 'auto', height: '44px', typography: 'body1', fontWeight: '600' }} />
        </Tooltip>
      </Tabs>
      <Box sx={{ display: 'flex', flexDirection: isMobile ? 'column' : 'row', alignItems: 'center', gap: 2 }}>
        {(activeTab === 0 || activeTab === 1 || activeTab === 2) && (
          <IconButton
            sx={{
              padding: '8px 16px',
              width: '136px',
              height: '44px',
              border: '1px solid #D3D3D3',
              borderRadius: '12px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '8px'
            }}
            onClick={handleImportClick}
          >
            <DownloadIcon />
            <Typography variant="subtitle1" sx={{ fontWeight: 400, fontSize: '24px', color: '#5C5C5C' }}>Import</Typography>
          </IconButton>
        )}
        <Button
          variant="contained"
          onClick={handleAddButtonClick}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            padding: '12px 16px',
            width: '250px',
            height: '48px',
            background: '#3F3F3F',
            borderRadius: '12px',
            color: '#FFFFFF',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '8px',
            '&:hover': { backgroundColor: '#333' }
          }}
        >
          <AddCircleOutlineIcon />
          {activeTab === 0 ? (
            <Typography variant="subtitle1">Add Machine</Typography>
          ) : activeTab === 1 ? (
            <Typography variant="subtitle1">Add Equipment</Typography>
          ) : (
            <Typography variant="subtitle1">Add Inventory</Typography>
          )}
        </Button>
      </Box>
      <AddInventoryDialog
        open={isAddInventoryOpen}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}
      />
      <AddEquipmentDialog
        open={isAddEquipmentOpen}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}
      />
      <AddMachineDialog
        open={isAddMachineOpen}
        update={update}
        setUpdate={setUpdate}
        flag={flag}
        setFlag={setFlag}
        onClose={() => { handleClose(activeTab) }}
      />
      <ImportMachine
        token={token}
        isOpen={isImportMachineOpen}
        onClose={() => setImportMachineOpen(false)}
      />
      <ImportEquipment
        token={token}
        isOpen={isImportEquipmentOpen}
        onClose={() => setImportEquipmentOpen(false)}
      />
      <ImportInventory
        token={token}
        isOpen={isImportInventoryOpen}
        onClose={() => setImportInventoryOpen(false)}
      />
    </Box>
  );
};

export default TopBarComponent;
